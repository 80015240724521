<template>
  <!-- eslint-disable -->
  <div>
    <Sidebar :data="isActive" v-on:closeSidebar="isActive = !isActive"/>
    <div class="dashboard-body" :class="{'db-close':isActive}">
      <div class="menu-icon" :class="{'close': isActive}" @click="isActive = !isActive">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Sidebar from './components/Sidebar.vue';
export default {
  name: 'LayoutSidebar',
  components: {
    Sidebar,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // Todo: make api call for logout
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData');
      localStorage.removeItem('is_driver');

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
