<template>
  <!-- eslint-disable -->
  <div class="sidenav" :class="{ open: data }">
    <div class="dahboard-logo">
      <figure>
        <img :src="require('@assets/images/inner-logo.svg')" />
      </figure>
      <!-- <figcaption>Customer Login</figcaption> -->
    </div>
    <div class="user-profile">
      <button
        class="dropdown-btn"
        @click.prevent="toggleUserDropDown('user')"
        ref="dropdown-btn-user"
      >
        <div class="user-image">
          <avatar
            v-if="profileImage == null"
            :username="logginUserName"
            class="w-100 h-100"
          ></avatar>
          <img v-else :src="profileImage" alt="" />
        </div>
        <span>{{ logginUserName }}</span>
        <i class="fas fa-sort-down"></i>
      </button>
      <div class="dropdown-container" ref="dropdown-container-user">
        <!-- <a class="active" href="#">Edit Profile</a> -->
        <router-link
          :to="{ name: 'editprofile' }"
          tag="a"
          @click.native="screenWidth ? $emit('closeSidebar', 'closeSidebar') : null"
          :class="{ active: 'editprofile' == $route.name }"
          >Edit profile</router-link
        >
        <router-link
          :to="{ name: 'changePassword' }"
          tag="a"
          @click.native="screenWidth ? $emit('closeSidebar', 'closeSidebar') : null"
          :class="{ active: 'changePassword' == $route.name }"
          >Change password</router-link
        >
        <!-- <router-link
          :to="{ name: 'paymentDetails' }"
          tag="a"
          @click.native="screenWidth ? $emit('closeSidebar', 'closeSidebar') : null"
          :class="{ active: 'paymentDetails' == $route.name }"
          >Payment details</router-link
        > -->
        <!-- <a href="#">Payment Details</a> -->
        <a @click.prevent="logout" style="cursor: pointer">Log out</a>
      </div>
    </div>
    <div class="sidebar-menu">
      <ul>
        <li
          :class="{ active: menu.routeName == $route.name }"
          v-for="(menu, menuIndex) in menus"
          :key="`${menu.label}-${menuIndex}`"
        >
          <!-- dropdown menu -->
          <a
            href="javascript:;"
            @click.prevent="toggleDropdownMenu(menuIndex)"
            class="dropdown-btn"
            :ref="`dropdown-btn-${menuIndex}`"
            v-if="menu.subMenus && menu.subMenus.length > 0"
          >
            <!-- <span :class="menu.routeName == $route.name ? 'blue' : 'black'"> -->
            <span :class="$route.name == 'bookings' ? 'blue' : 'black'">
              <img :src="require(`@assets/images/${menu.image}`)" :alt="`${menu.label} menu`" />
              <!-- <img
                :src="
                  require(`@assets/images/${menu.routeName == $route.name ? 'blue' : 'black'}-${
                    menu.image
                  }`)
                "
                :alt="`${menu.label} menu`"
              /> -->
            </span>
            <h5>Booking</h5>
            <i class="fas fa-sort-down"></i>
          </a>
          <div
            v-if="menu.subMenus && menu.subMenus.length > 0"
            class="dropdown-container"
            :ref="`dropdown-container-${menuIndex}`"
          >
            <router-link
              tag="a"
              :to="{
                name: submenu.routeName,
                params: submenu.hasOwnProperty('params') ? submenu.params : {},
                query: submenu.hasOwnProperty('query') ? submenu.query : {},
              }"
              @click.native="screenWidth ? $emit('closeSidebar', 'closeSidebar') : null"
              :class="{ active: isMenuActive(submenu) }"
              v-for="(submenu, subMenuIndex) in menu.subMenus"
              :key="`${submenu.label}-${menuIndex}-${subMenuIndex}`"
            >
              {{ submenu.label }}
            </router-link>
          </div>
          <!-- /dropdown menu -->

          <router-link
            v-else-if="
              menu.label != 'Driver-Bookings' ||
              (menu.label == 'Driver-Bookings' && is_driver == 11)
            "
            tag="a"
            :to="{
              name: menu.routeName,
              params: menu.hasOwnProperty('params') ? menu.params : {},
              query: menu.hasOwnProperty('query') ? menu.query : {},
            }"
            @click.native="screenWidth ? $emit('closeSidebar', 'closeSidebar') : null"
            :class="{ 'dropdown-btn': menu.subMenus && menu.subMenus.length > 0 }"
          >
            <span :class="menu.routeName == $route.name ? 'blue' : 'black'">
              <img :src="require(`@assets/images/${menu.image}`)" :alt="`${menu.label} menu`" />
              <!-- <img
                :src="
                  require(`@assets/images/${menu.routeName == $route.name ? 'blue' : 'black'}-${
                    menu.image
                  }`)
                "
                :alt="`${menu.label} menu`"
              /> -->
            </span>
            <h5>{{ menu.label }}</h5>
            <span class="msgcount" v-if="unreadMessageCount > 0 && menu.routeName == 'message'">{{
              unreadMessageCount
            }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import useJwt from '@/auth/jwt/useJwt';
import Avatar from 'vue-avatar';
import { mapState } from 'vuex';
import { getUserData } from '@/auth/utils';

export default {
  name: 'Sidebar',
  components: {
    Avatar,
  },
  props: {
    data: Boolean,
  },
  data() {
    return {
      logginUserName: '',
      profileImage: null,
      screenWidth: false,
      is_driver: localStorage.getItem('is_driver'),
      customerDevicetoken: null,
      menus: [
        {
          label: 'Dashboard',
          image: 'black-dashboard.svg',
          routeName: 'dashboard',
        },
        {
          label: 'Booking',
          image: 'black-booking.svg',
          routeName: 'bookings',
          isSubMenuVisible: false,
          subMenus: [
            {
              label: 'All bookings',
              routeName: 'bookings',
            },
            // {
            //   label: 'Posted Bookings',
            //   routeName: 'bookings',

            // },
            // {
            //   label: 'Draft Bookings',
            //   routeName: 'bookings',
            // },
            {
              label: 'Bookings assigned',
              routeName: 'bookings',
              query: {
                status: 'Accept',
              },
            },
            {
              label: 'Bookings completed',
              routeName: 'bookings',
              query: {
                status: 'Complete',
              },
            },
          ],
        },
        // {
        //   label: 'Map',
        //   image: 'black-map.svg',
        //   routeName: 'map',
        // },
        {
          label: 'Messages',
          image: 'black-msg.svg',
          routeName: 'message',
        },
        // {
        //   label: 'Settings',
        //   image: 'setting.png',
        //   routeName: 'home8',
        // },
        {
          label: 'Support',
          image: 'black-support.svg',
          routeName: 'support',
        },
        {
          label: 'Driver-Bookings',
          image: 'black-booking.svg',
          routeName: 'driverbooking',
        },
      ],
    };
  },
  computed: {
    ...mapState(['unreadMessageCount']),
  },
  created() {
    var userdata = JSON.parse(localStorage.getItem('userData'));
    const roleDriver = userdata
      ? userdata['roles'].filter((e) => e.name.toLowerCase() == 'driver')
      : '';
    if (roleDriver.length > 0) {
      this.is_driver = 1;
    } else if (localStorage.getItem('is_driver')) {
      this.is_driver = 1;
    } else {
      this.is_driver = 0;
    }
    if (userdata) {
      this.logginUserName = userdata.full_name;
      this.profileImage = userdata.image_url;
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    const isPushNotificationsEnabled = localStorage.getItem('isPushNotificationsEnabled');
    if (isPushNotificationsEnabled) {
      this.$OneSignal.getUserId().then((response1) => {
        if (response1) {
          this.customerDevicetoken = response1;
        }
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 330) {
        this.screenWidth = true;
      } else {
        this.screenWidth = false;
      }
    },
    isMenuActive(menuObj) {
      if (Object.keys(this.$route.query).length > 0) {
        if (menuObj.hasOwnProperty('query') && 'status' in menuObj.query) {
          return this.$route.query.status == menuObj.query.status ? true : false;
        }
      } else if (!menuObj.hasOwnProperty('query')) {
        return menuObj.routeName == this.$route.name;
      }
    },
    toggleUserDropDown(id) {
      if (
        this.$refs[`dropdown-container-${id}`].style.display == 'none' ||
        this.$refs[`dropdown-container-${id}`].style.display == ''
      ) {
        this.$refs[`dropdown-container-${id}`].style.display = 'block';
      } else {
        this.$refs[`dropdown-container-${id}`].style.display = 'none';
      }
      this.$refs[`dropdown-btn-${id}`].classList.toggle('active');
    },
    toggleDropdownMenu(id) {
      if (
        this.$refs[`dropdown-container-${id}`][0].style.display == 'none' ||
        this.$refs[`dropdown-container-${id}`][0].style.display == ''
      ) {
        this.$refs[`dropdown-container-${id}`][0].style.display = 'block';
      } else {
        this.$refs[`dropdown-container-${id}`][0].style.display = 'none';
      }
      this.$refs[`dropdown-btn-${id}`][0].classList.toggle('active');
    },
    logout() {
      // this.$http
      //   .post('customer/setup-devicetoken', { customer_devicetoken: null })
      //   .then((res) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     useJwt.logOut();
      //   });
      this.$http
        .post('logout', { customer_devicetoken: this.customerDevicetoken })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          useJwt.logOut();
        });
    },
  },
};
</script>
